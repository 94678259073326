(function($){
    'use strict';

    // Has js
    $('html')
        .removeClass('no-js')
        .addClass('js');

    // Menu mobile
    $('.btn-menu').on('click', function(){
        $(this).toggleClass('open');
    });

    // Change fake placehoder state on correspondant input focus
	if(!document.implementation.hasFeature('http://www.w3.org/TR/SVG11/feature#Image', '1.1')){
        $('img[src$=".svg"]').each(function(i, elt){
            elt.src = elt.src.replace('.svg', '.png');
        });
    }

    // Qazal
    $('[data-qazal]')
        .on('mouseenter', function(){
            $('.qazal-description').removeClass('active');
            $('.qazal-description-'+$(this).data('qazal')).addClass('active');
            $('[data-qazal]').css('fill', 'transparent');
            $(this).css('fill', 'rgba(255,255,255,0.3)');
        });

    // Carte de france
    var carteDeFrance = $('.carte-france');
    var referenceRegions = $('.reference-region h3');

    $('[data-carte]')
        .on('mouseenter', function(){
            carteDeFrance.find('.region-popin').remove();
            var region = carteDeFrance.find('[data-carte=' + $(this).data('carte') + ']');
            var regionName = $('.region-' + $(this).data('carte'));

            if(regionName.length == 0)
                return;

            var popin = $('<div class="region-popin">' + regionName.html() + '</div>');
            carteDeFrance.append(popin);

            popin.css({
                top: Math.round(region.position().top - carteDeFrance.offset().top - popin.outerHeight() / 2),
                left: Math.round(region.position().left - carteDeFrance.offset().left + region[0].getBBox().width / 2 - popin.outerWidth() / 2)
            });

            regionName.addClass('active');
        })
        .on('mouseleave', function(){
            carteDeFrance.find('.region-popin').remove();
            $('.region-' + $(this).data('carte'))
                .removeClass('active')
        })
        .click(function(){
            $('.reference-region').removeClass('open');
            $('.reference-region-' + $(this).data('carte')).addClass('open');
        });

    referenceRegions
        .click(function(){
            $(this).toggleClass('open');
        });

    // slideshow
    var slideshow = $('.slideshow');
    if(slideshow.length > 0) slideshow.slick({
        dots: true,
        speed: 300,
        easing: 'swing',
        prevArrow: '<button type="button" class="slick-prev button button-arrow-left">Précédent</button>',
        nextArrow: '<button type="button" class="slick-next button button-arrow-right">Suivant</button>'
    });
})(jQuery);
